const styles = (theme) => ({
  container: {
    height: "100%",
  },
  panel: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    height: "100%",
    "& > div:last-child": {
      height: "calc(100% - 56px)",
      "& > div": {
        height: "100%",
      },
    },
  },
  panelcontent: {
    height: "calc(100% - 104px)",
    padding: 16,
    paddingBottom: 0,
    overflow: "hidden",
    overflowY: "auto",
  },
  panelresultcontent: {
    height: "100%",
    padding: 16,
    paddingBottom: 0,
    overflow: "hidden",
    overflowY: "auto",
  },
});

export default styles;
