import { withStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { HorizontalDataTable, CustomLinearProgress } from "@orbit/components";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import MapPublicInvestigationResultView from "views/map-views/MapPublicInvestigationResultView";
import styles from "./PublicInvestigationResultStyles";

const title = "";

const removeDuplicates = (result) => {
  return Object.values(
    result.reduce((acc, record) => {
      const key = `${record.Straat}_${record.Huisnummer}_${record.Busnummer}_${record.Postcode}_${record.Gemeente}`;
      return acc[key] ? acc : { ...acc, [key]: record };
    }, {}),
  );
};

const parseFields = (fieldsToHide, data) => {
  return data.map((item) => {
    const newItem = { ...item };
    fieldsToHide.forEach((field) => {
      delete newItem[field];
    });

    if (newItem.Voornamen && Array.isArray(newItem.Voornamen)) {
      newItem.Voornamen = newItem.Voornamen.join(", ");
    }

    if (newItem.Achternaam && Array.isArray(newItem.Achternaam)) {
      newItem.Achternaam = newItem.Achternaam.join(", ");
    }
    return newItem;
  });
};

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          height: "inherit",
        },
        responsiveScroll: {
          maxHeight: "100% !important",
          height: "calc(100% - 117px)",
        },
        overflow: "hidden",
        overflowX: "auto",
      },
      MUIDataTableFilterList: {
        root: {
          height: 0,
        },
      },
      MUIDataTableToolbar: {
        root: {
          display: "block",
        },
      },
      MUIDataTableHeadRow: {
        root: {
          "& th:not(:first-child)": {
            padding: "0 10px !important",
          },
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "& td:not(:first-child)": {
            padding: "0 10px !important",
          },
          "& td:nth-child(2)": {
            padding: "0 24px !important",
          },
        },
      },
    },
  });

const PublicInvestigationResult = hooksObserver(({ classes, match: { params: { id } } }) => {
  const {
    uiStore: { duplicateSpatialResearchResult, mapSpatialResearchResult, parcelsInsideMunicipality },
    applicationStore: { publicInvestigation: { fetchResults, titel, geoJsonData }, toggleLoading, loading },
  } = useContext(StoresContext);

  const options = {
    download: true,
    downloadOptions: {
      filename: titel + `.csv`,
    },
    print: false,
    responsive: "standard",
    search: false,
    sort: true,
    filterType: "dropdown",
    rowsPerPage: 25,
    tableBodyHeight: "calc(100vh - 178px)",
    rowsPerPageOptions: [25, 50, 100],
    textLabels: {
      body: {
        noMatch: "Geen info gevonden...",
        toolTip: "Sorteren",
        //columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: "Volgende pagina",
        previous: "Vorige pagina",
        rowsPerPage: "Rijen per pagina:",
        displayRows: "van",
      },
      toolbar: {
        search: "Zoeken",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "Bekijk kolommen",
        filterTable: "Filter",
      },
      filter: {
        all: "Alles",
        title: "FILTERS",
        reset: "Beginwaarden",
      },
      viewColumns: {
        title: "Toon kolommen",
        titleAria: "Toon/verberg kolommen",
      },
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      console.log(rowsDeleted, dataRows);
    },
  };

  const [results, setResults] = useState([]);

  useEffect(() => {
    const getResults = async () => {
      toggleLoading();
      const result = await fetchResults(id);
      console.log("result", result);
      if (result) {
        setResults(result);
      }
      toggleLoading();
    };
    getResults();
  }, []);

  const visibleResult = useMemo(() => {
    return parcelsInsideMunicipality
      ? parseFields(["NISCodeLand", "PartnerId"], duplicateSpatialResearchResult ? removeDuplicates(results) : results)
      : geoJsonData.features
          .filter((feature) => feature.properties.isOutsideBoundary)
          .map((feature) => {
            return { capakey: feature.properties.capakey };
          });
  }, [results, duplicateSpatialResearchResult, parcelsInsideMunicipality, geoJsonData]);

  /**
     "Naam",
     "Voornaam",
     "Geboortedatum",
     "Straat",
     "Huisnummer",
     "Postcode",
     "Gemeente",
     "Land"
     */
  /*
   * De kolommen tussen haakjes zijn optioneel (indien ze te veel ruimte innemen, hoeven ze er niet bij te zitten)
   *
   * Ligging (A, B, I of C)
   * Capakey
   * Partitienr
   * (Afdeling)
   * (Sectie)
   * (Perceelnr)
   * Straat + Nummer
   * Rechten
   * Naam
   * Voornaam
   * (Identificatienr)
   * Naam partner
   * Voornaam partner
   * (Identificatienr partner)
   */

  return !mapSpatialResearchResult ? (
    <div className={classes.container}>
      {loading && <CustomLinearProgress variant="query" color={"secondary"} />}
      <div className={classes.tableWrapper} id="public-investigation-table" /* mui-datatables style not in mui5 => public/table.css */>
        {!loading && (
          <HorizontalDataTable
            title={title}
            data={visibleResult}
            columns={parcelsInsideMunicipality ? visibleResult && visibleResult[0] && Object.keys(visibleResult[0]) : ["capakey"]}
            options={options}
            muiTheme={getMuiTheme}
          />
        )}
      </div>
    </div>
  ) : (
    <MapPublicInvestigationResultView />
  );
});

PublicInvestigationResult.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PublicInvestigationResult));
