const styles = (theme) => ({
  panel: {
    borderRadius: "0 !important",
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    "& > div > div > p": {
      paddingRight: "0 !important",
    },
    "& > div:last-child": {
      transition: "all 0s !important",
    },
  },
  panelExpanded: {
    borderRadius: "0 !important",
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    height: "calc(100% - 201px)",
    "& > div > div > p": {
      paddingRight: "0 !important",
    },
    "& > div:last-child": {
      transition: "all 0s !important",
      height: "calc(100% - 56px) !important",
    },
  },
  container: {
    height: "100%",
  },
  panelcontainer: {
    height: "100%",
  },
  panelcontainerclosed: {
    height: 50,
  },
  panelsummary: {
    paddingRight: "0 !important",
    height: 50,
  },
  panelcontent: {
    height: "calc(100% - 50px)",
  },
  panelresultcontent: {
    height: "100%",
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export default styles;
