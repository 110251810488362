import { Grid, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import CreateIcon from "@mui/icons-material/Create";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";
import StorageIcon from "@mui/icons-material/Storage";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { PUBLIC_INVESTIGATION_TYPES } from "stores/models/PublicInvestigationModel";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "./CustomExpansionPanels";
import NextSteps from "./NextSteps";
import styles from "./StartStyles";
import Stepper from "./Stepper";
import { SubscriptionMessage } from "views/advanced-search/search-subscription-message";
import { SUBSCRIPTION_CODE } from "app-constants";

const panels = [
  {
    title: "Contour zelf tekenen op kaart",
    icon: <CreateIcon style={{ marginRight: 10 }} />,
    type: PUBLIC_INVESTIGATION_TYPES.DRAW,
    intro: <Typography>Start een onderzoek vanuit een contour die je zelf op kaart tekent.</Typography>,
    content: <Typography>Test</Typography>,
  },
  {
    title: "Percelen selecteren op kaart",
    type: PUBLIC_INVESTIGATION_TYPES.SELECT_SHAPES,
    intro: <Typography>Start een onderzoek vanuit een selectie van een of meerdere percelen op kaart.</Typography>,
    icon: <MapIcon style={{ marginRight: 10 }} />,
    content: <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>,
  },
  {
    title: "Omgevingsvergunning zoeken",
    type: PUBLIC_INVESTIGATION_TYPES.SEARCH,
    icon: <SearchIcon style={{ marginRight: 10 }} />,
    intro: (
      <>
        <Typography>Start een onderzoek vanuit een bestaande omgevingsvergunning.</Typography>
        <SubscriptionMessage code={SUBSCRIPTION_CODE.SEARCH_OMV} />
      </>
    ),
  },
  {
    title: "Geo dataset importeren",
    type: PUBLIC_INVESTIGATION_TYPES.IMPORT_SHAPE,
    icon: <ImportExportIcon style={{ marginRight: 10 }} />,
    intro: <Typography>Start een onderzoek vanuit een bestand: SHP, Geojson, WKT.</Typography>,
  },
  {
    title: "Lijst met perceelnummers importeren",
    type: PUBLIC_INVESTIGATION_TYPES.IMPORT_DATASET,
    icon: <StorageIcon style={{ marginRight: 10 }} />,
    intro: <Typography>Start een onderzoek vanuit een percelenlijst bestand.</Typography>,
  },
];

const StartPublicInvestigationPanel = hooksObserver(({ classes }) => {
  const {
    applicationStore: { publicInvestigationId, publicInvestigation, startPublicInvestigation, publicInvestigation: { setTitle, titel, getStepValidator, location }, loading },
  } = useContext(StoresContext);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(getStepValidator);
  }, [location]);

  const [expanded, setExpanded] = React.useState(publicInvestigation.type || -1);

  return (
    <div className={classes.container}>
      {publicInvestigation.status < 5 && (
        <div className={publicInvestigation.status === 0 ? classes.panelcontainer : classes.panelcontainerclosed}>
          {panels.map((panel, index) => {
            /* TODO reset check  */
            if (publicInvestigation.status > 0 && publicInvestigation.type !== panel.type) {
              return null;
            }
            return (
              <ExpansionPanel
                key={index}
                onChange={() => {
                  if (panel.type !== expanded) {
                    setExpanded(panel.type);
                  } else {
                    setExpanded(-1);
                  }
                  publicInvestigation.setType(panel.type);
                }}
                expanded={expanded === panel.type && publicInvestigation.status === 0}
                className={expanded === panel.type && publicInvestigation.status === 0 ? classes.panelExpanded : classes.panel}
              >
                <ExpansionPanelSummary className={classes.panelsummary}>
                  <Typography style={{ verticalAlign: "top", display: "inline-flex" }}>
                    {panel.icon}
                    {panel.title}
                  </Typography>
                </ExpansionPanelSummary>
                {publicInvestigation.status === 0 && (
                  <ExpansionPanelDetails>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                      <Grid item>
                        <Stepper
                          activeStep={publicInvestigation.status}
                          handleNext={() => {
                            startPublicInvestigation();
                          }}
                          handleBack={() => {
                            publicInvestigation.setStatus(publicInvestigation.status - 1);
                          }}
                        />
                      </Grid>
                      <Grid item style={{ padding: 20 }}>
                        {panel.intro}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                )}
              </ExpansionPanel>
            );
          })}
        </div>
      )}
      <div className={publicInvestigation.status === 5 ? classes.panelresultcontent : classes.panelcontent}>
        {publicInvestigation.status > 0 && (
          <NextSteps>
            <TextField
              id="standard-name"
              label="Omschrijving"
              className={classes.textField}
              placeholder={"Vul een omschrijving in..."}
              value={titel}
              autoFocus
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              variant="filled"
              fullWidth
            />
            <Stepper
              activeStep={publicInvestigation.status}
              handleNext={() => {
                publicInvestigation.setStatus(publicInvestigation.status + 1);
              }}
              handleBack={() => {
                publicInvestigation.setStatus(publicInvestigation.status - 1);
              }}
              disableNext={disabled || loading || publicInvestigation.status > 3}
            />
            {loading}
          </NextSteps>
        )}
      </div>
    </div>
  );
});

export default withStyles(styles)(StartPublicInvestigationPanel);
