import { Grid, Link, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ErrorIcon from "@mui/icons-material/Error";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import { withStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { getLocationForCapakey, getLocationsForCapakeys } from "services/api";
import styles from "./CapakeyInputListStyles";
import { toJS } from "mobx";

const showParcel = async (capakey, setLatLng) => {
  try {
    const { lat, lng } = await getLocationForCapakey(capakey);
    setLatLng({ lat, lng });
  } catch (e) {
    console.error(e);
  }
};

const getParcelsWithLocation = async (capakeys) => {
  const parcels = {};
  const notfound = [];
  if (capakeys.length > 0) {
    const data = await getLocationsForCapakeys(capakeys);
    for (const capakey of capakeys) {
      const found = data.features.find((item) => item.properties.CAPAKEY === capakey);
      if (!found) {
        notfound.push(capakey);
      }
    }
    for (const item of data.features) {
      const capakey = item.properties.CAPAKEY;
      parcels[capakey] = {
        type: "C",
        CAPAKEY: capakey,
        coordinates: item.geometry.coordinates,
      };
    }
  }

  return { parcels, notfound };
};

const CapakeyInputList = hooksObserver(({ classes }) => {
  const {
    applicationStore: { publicInvestigation: { setSelectedParcels, selectedParcels } },
    mapStore: { setLatLng },
  } = useContext(StoresContext);

  const [enteredCapakeys, setEnteredCapakeys] = useState([]);
  const [notFound, setNotFound] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [length, setLength] = useState(enteredCapakeys.length);

  const validateCapakeys = (e, setter) => {
    const enteredCapakeys = [];
    setInputValue(e.target.value);
    e.target.value
      .replace(/ /gi, "")
      .replace(/,|\r|\n/gi, ";")
      .split(";")
      .forEach((capakey) => {
        const re = new RegExp("\\d{5}[A-Z_]{1}\\d{4}\\/\\d{2}[A-Z_]\\d{3}");
        const [result] = capakey.match(re) || [null];
        if (result === capakey && !enteredCapakeys.includes(capakey)) {
          enteredCapakeys.push(capakey);
        }
      });
    setter(enteredCapakeys);
  };

  const handleBlur = async () => {
    setNotFound([]);
    const { parcels, notfound } = await getParcelsWithLocation(enteredCapakeys);
    if (parcels) {
      setSelectedParcels(parcels);
      setNotFound(notfound);
    }
  };

  // @TODO: Bij het refreshen gebeurd de autosave nog voor deze effect (waarschijnlijk) en dus is de lijst leeg na een tweede refresh
  useEffect(() => {
    if (length !== enteredCapakeys.length || length === 0) {
      handleBlur();
      setLength(enteredCapakeys.length);
    }
  }, [enteredCapakeys]);

  useEffect(() => {
    if (Object.keys(selectedParcels).length > 0 && enteredCapakeys.length === 0) {
      let capakeys = "";
      Object.keys(selectedParcels).forEach((parcel) => (capakeys += `${parcel};`));
      validateCapakeys({ target: { value: capakeys } }, setEnteredCapakeys);
    }
  }, [selectedParcels]);

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  const downloadNotFound = () => {
    const blob = new Blob([notFound.join("\n")], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `capakeys_not_found_${new Date().toISOString()}.txt`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          Plak een lijst van perceelnummers gescheiden door een punt-komma (;) of komma (,) in onderstaand veld.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="outlined-multiline-static"
          label="Lijst met perceelnummers"
          fullWidth
          multiline
          rows="8"
          placeholder="Bijvoorbeeld: 44815F2207/00F000;44815F2254/00D002;44815F2317/00P000"
          margin="normal"
          variant="outlined"
          value={inputValue}
          onChange={(e) => {
            validateCapakeys(e, setEnteredCapakeys);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" gutterBottom>
          Aantal percelen: {length}
        </Typography>
        {notFound.length > 0 && (
          <>
            <br />
            <Typography variant="caption" color="error" gutterBottom>
              Niet gevonden: {notFound.length} - <Link onClick={downloadNotFound}>Klik hier om te exporteren</Link>
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <List dense className={classes.list}>
          {enteredCapakeys.map((capakey) => {
            const capakeyNotFound = notFound.includes(capakey);
            return (
              <ListItemButton
                key={capakey}
                onClick={() => {
                  capakeyNotFound ? copyToClipboard(capakey) : showParcel(capakey, setLatLng);
                }}
              >
                <ListItemText primary={capakey} />
                {capakeyNotFound ? <ErrorIcon /> : <ArrowRightIcon />}
              </ListItemButton>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
});

export default withStyles(styles)(CapakeyInputList);
